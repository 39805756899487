/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const roomConfigState = (state: RootState) => state.roomConfig || initialState;

export const selectLoading = createSelector(
  [roomConfigState],
  roomConfig => roomConfig.loading,
);

export const selectSuccess = createSelector(
  [roomConfigState],
  roomConfig => roomConfig.success,
);

export const selectError = createSelector(
  [roomConfigState],
  roomConfig => roomConfig.error,
);

export const selectallRoomConfig = createSelector(
  [roomConfigState],
  roomConfig => roomConfig.allRoomConfig,
);

export const selectRoomConfigsNumber = createSelector(
  [roomConfigState],
  roomConfig => roomConfig.roomConfigsNumber,
);

export const selectroomConfigsNoNumber = createSelector(
  [roomConfigState],
  roomConfig => roomConfig.roomConfigsNoNumber,
);

export const selectRoomConfig = createSelector(
  [roomConfigState],
  roomConfig => roomConfig.roomConfig,
);
