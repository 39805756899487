/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Navbar } from './Navbar';
import PropTypes from 'prop-types';
import ContentTop from './ContentTop/ContentTop';
export function Header() {
  return (
    <div
      className="page-header navbar navbar-fixed-top"
      style={{ zIndex: 101 }}
    >
      <ContentTop />
      <Navbar />
    </div>
  );
}
Header.propTypes = {
  routers: PropTypes.array,
};
