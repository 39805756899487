import { lazyLoad } from 'utils/loadable';
import { LoaderFallBack } from 'app/components/Loading';

export const DashboardPage = lazyLoad(
  () => import('app/containers/Dashboard'),
  module => module.DashboardPage,
  LoaderFallBack(),
);

export const DetailedReportPage = lazyLoad(
  () => import('app/containers/Dashboard'),
  module => module.DetailedReportPage,
  LoaderFallBack(),
);

export const LoginPage = lazyLoad(
  () => import('app/containers/LoginPage'),
  module => module.LoginPage,
  LoaderFallBack(),
);

export const UserPage = lazyLoad(
  () => import('app/containers/UserPage'),
  module => module.UserPage,
  LoaderFallBack(),
);

export const UserManagement = lazyLoad(
  () => import('app/containers/PersonnelPage'),
  module => module.HRM,
  LoaderFallBack(),
);

export const Role = lazyLoad(
  () => import('app/containers/SettingPage'),
  module => module.Role,
  LoaderFallBack(),
);

export const RoleDetail = lazyLoad(
  () => import('app/containers/SettingPage'),
  module => module.RoleDetail,
  LoaderFallBack(),
);

export const SettingRoom = lazyLoad(
  () => import('app/containers/RoomOnline'),
  module => module.SettingRoom,
  LoaderFallBack(),
);

export const Chanel = lazyLoad(
  () => import('app/containers/Chanel'),
  module => module.Channel,
  LoaderFallBack(),
);

export const OnlineNumber = lazyLoad(
  () => import('app/containers/OnlineNumber'),
  module => module.OnlineNumber,
  LoaderFallBack(),
);

export const SwitchBoard = lazyLoad(
  () => import('app/containers/SwitchBoard'),
  module => module.SwitchBoard,
  LoaderFallBack(),
);

export const RegisterNumber = lazyLoad(
  () => import('app/containers/RegisterNumber'),
  module => module.RegisterNumber,
  LoaderFallBack(),
);

export const DoctorPage = lazyLoad(
  () => import('app/containers/Management'),
  module => module.DoctorPage,
  LoaderFallBack(),
);
