import { makeStyles, TextField, withStyles } from '@material-ui/core';
import logoUrlCR from 'img/logo-choray.png';
import logoUrlTN from 'img/logo2.png';

type HopitalCodeType = 'BVK' | 'BVTN';

export const HOSPITAL_CODE: HopitalCodeType =
  (process.env.REACT_APP_HOSPITAL as HopitalCodeType) || 'BVK';

interface HospitalInfoType {
  logoUrl: string;
  backgroundUrl: string;
  viName: string;
  enName: string;
  slogan: string;
  code: string;
}
export const HOPITAL_INFO: HospitalInfoType =
  HOSPITAL_CODE === 'BVTN'
    ? {
        logoUrl: logoUrlTN,
        backgroundUrl: '',
        viName: 'Bệnh viện Thống Nhất',
        enName: 'THONG NHAT HOSPITAL',
        slogan: '',
        code: 'BVTN',
      }
    : {
        logoUrl: logoUrlCR,
        backgroundUrl: '',
        viName: 'BỆNH VIỆN ĐẠI HỌC Y KHOA VINH',
        enName: 'CHÍ THANH CLINIC',
        slogan: '',
        code: 'BVK',
      };

export const APP_CONSTANT = {
  API: {
    ENDPOINT: process.env.REACT_APP_ENDPOINT,
    AUTH: process.env.REACT_APP_AUTH,
  },
};

export const CommonApiRoute = {
  SETTING: {
    GET_SETTING: '/api/GeneralSetting',
    ADD_UPDATE_SETTING: '/api/GeneralSetting/add-or-update',
  },
  GET_LOCATIONS: '/api/Location/all',
};

export const RESPONSE_CONSTANT = {
  CREATE_SETTING_SUCCESSS: 'CREATE_SETTING_SUCCESSS',
  CREATE_SETTING_FAIL: 'CREATE_SETTING_FAIL',
  UPDATE_SETTING_SUCCESSS: 'UPDATE_SETTING_SUCCESSS',
  UPDATE_SETTING_FAIL: 'UPDATE_SETTING_FAIL',
};

export const RESPONSE_MESSAGE = {
  CREATE_SETTING_SUCCESSS: 'Tạo mới Cài đặt chung thành công',
  CREATE_SETTING_FAIL: 'Tạo mới Cài đặt chung thành công',
  UPDATE_SETTING_SUCCESSS: 'Cập nhật Cài đặt chung thành công',
  UPDATE_SETTING_FAIL: 'Cập nhật Cài đặt chung thành công',
};

export const DEFAULT_FORMAT_DATE = 'dd/MM/yyyy';
export const MOMENT_RENDER = 'DD-MM-YYYY';
export const MOMENT_CALL_API = 'yyyy-MM-DD';
export const MOMENT_HOUR_RENDER = 'HH:mm';
export const MOMENT_HOUR_CALL_API = 'HH:mm:ss';
export const DEFAULT_FORMAT_OUT_TIME = 'DD/MM/YYYY';
export const DEFAULT_ID = '00000000-0000-0000-0000-000000000000';

export enum Manipulation {
  RECHARGE = 'Nạp tiền',
  REGISTER = 'Ghi thẻ',
  NEW_CARD_ISSUANCE = 'Phí phát thẻ mới',
  DEPOSIT_CANCELED = 'Nạp tiền (đã hủy)',
  LOST_NEW_CARD = 'Mất thẻ phát mới',
  WITHDRAWAL = 'Rút tiền',
  RETURN_THE_CARD = 'Trả thẻ',
  LOST_NOT_NEW_CARD = 'Mất thẻ không phát mới',
}

export const EMPTY_MESSAGE = 'Không tìm thấy thông tin';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '80vh',
  },
  table: {
    minWidth: '100vw',
    overflowX: 'scroll',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  td_total: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#000',
  },
  td: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
  },
  red: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: 'red',
  },
  Green: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#00CD00',
  },
  Blue: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#3399ff',
  },
  SkyBlue: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#87CEEB',
  },
  Orchid: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#DA70D6',
  },
  Purple4: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#7D26CD',
  },
  Yellow: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#F09C42',
  },
  GreenYellow: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#FF34B3',
  },
  btn: {
    textAlign: 'center',
  },
  tdName: {
    fontWeight: 'bolder',
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
  },
  tdActive: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto, sans-serif',
    border: '1px soild #ccc',
    padding: '5px 16px',
    borderRadius: '10px',
  },
});

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#555',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid',
        borderColor: '#8cbbec',
      },
      '& .MuiAutocomplete-popper': {
        top: '-5px',
      },
      '& .MuiAutocomplete-input': {
        color: '#000',
      },
    },
  },
})(TextField);
