import { HttpRequest } from 'utils/request';
import { APP_CONSTANT } from 'store/common/constants';
import { CommonHttp } from 'store/common/services/common.http';
import { ExamRoute } from '../constants';
import { AppHelper } from 'utils/app.helper';

export class ExamHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  registerExam = (exam: any): Promise<any> => {
    return this.request.post(ExamRoute.REGISTER_EXAM, {
      ...CommonHttp.convertRequest(exam),
      registered_date: AppHelper.formatDateAPI(exam.registeredDate),
    });
  };

  cancelExam = (bookingCode: string): Promise<any> => {
    return this.request.post(ExamRoute.CANCEL_EXAM, {
      booking_code: bookingCode,
    });
  };
}
