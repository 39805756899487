import { createSlice } from '@reduxjs/toolkit';
import { RESPONSE_CONSTANT } from 'store/examination/constants';

export interface ExamState {
  loading: boolean;
  error: any;
  success?: any;
}

export const initialState: ExamState = {
  loading: false,
  error: null,
  success: {},
};

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    registerExam(state, action) {
      state.loading = true;
      state.error = null;
    },

    registerExamSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.REGISTER_EXAM_SUCCESS,
        data: action.payload,
      };
    },

    registerExamFail(state, action) {
      state.loading = false;
      state.error = {
        key: RESPONSE_CONSTANT.REGISTER_EXAM_FAIL,
        message: action.payload,
      };
    },

    cancelExam(state, action) {
      state.loading = true;
      state.error = null;
    },

    cancelExamSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.CANCEL_EXAM_SUCCESS,
        message: RESPONSE_CONSTANT.CANCEL_EXAM_SUCCESS,
      };
    },

    cancelExamFail(state, action) {
      state.loading = false;
      state.error = {
        key: RESPONSE_CONSTANT.CANCEL_EXAM_FAIL,
        message: RESPONSE_CONSTANT.CANCEL_EXAM_FAIL,
        data: action.payload,
      };
      state.success = null;
    },

    clearSuccess(state) {
      state.success = null;
    },

    clearError(state) {
      state.error = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = examSlice;
