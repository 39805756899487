export const RoomOnlineRoutes = {
  GET_ALL_ROOMS: '/api/DepartmentSetting',
  GET_ROOMS_BY_ID: '/api/SwitchBoardDepartment/get/department',
};

export const RESPONSE_CONSTANT = {
  SET_CONFIG_TO_ROOM_SUCCESS: 'SET_CONFIG_TO_ROOM_SUCCESS',
  SET_CONFIG_TO_ROOM_FAIL: 'SET_CONFIG_TO_ROOM_FAIL',
  ADD_NUMBER_SUCCESS: 'ADD_NUMBER_SUCCESS',
  ADD_NUMBER_FAIL: 'ADD_NUMBER_FAIL',
};

export const RESPONSE_MESSAGE = {
  REGISTER_EXAM_SUCCESS: 'Đăng ký khám thành công',
  REGISTER_EXAM_FAIL: 'Đăng ký khám thành công',
  ADD_NUMBER_SUCCESS: 'Cập nhật số thứ tự cho phòng khám thành công',
  SET_CONFIG_TO_ROOM_SUCCESS: 'Cài đặt cấu hình cho phòng khám thành công',
  UPDATE_CONFIG_TO_ROOM_SUCCESS: 'Cập nhật Cấu hình cho phòng khám thành công',
};
