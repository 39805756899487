/* eslint-disable no-throw-literal */
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { PrivateAuthRoute } from '../services/auth.route';
import { configResponse } from 'utils/request';
import { AppRouting } from 'router';
import * as _ from 'lodash';

import { actions } from './slice';
import { AuthHttp } from '../services/auth.http';
const authRequest = new AuthHttp();

export function* login(api, action) {
  const response = yield call(api.login, action.payload);
  try {
    const data = yield configResponse(response);
    const userInfo = yield SagaHelper.addUserRoute(data);
    yield put(actions.loginSuccess(userInfo));
  } catch (error) {
    yield put(actions.loginFail(_.get(error, 'message')));
  }
}

export function* getUserByIdToken(api, action) {
  const response = yield call(api.getUserByIdToken, action.payload);
  try {
    const data = yield configResponse(response);
    const userInfo = yield SagaHelper.addUserRoute(data);
    yield put(actions.getUserByIdTokenSucess(userInfo));
  } catch (error) {
    yield put(actions.getUserByIdTokenFail(_.get(error, 'message')));
  }
}
export function* getUserById(api, action) {
  const response = yield call(api.getUserById, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.getUserByIdSuccess(data));
  } catch (error) {
    yield put(actions.getUserByIdFail(_.get(error, 'message')));
  }
}

export function* getListUser(api, action) {
  const response = yield call(api.getListUser, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.getListUserSuccess(data));
  } catch (error) {
    yield put(actions.getListUserFail(_.get(error, 'message')));
  }
}

export function* addRole(api, action) {
  const response = yield call(api.addRole, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.addRoleSuccess(data));
  } catch (error) {
    yield put(actions.addRoleFail(_.get(error, 'message')));
  }
}

export function* deleteRole(api, action) {
  const response = yield call(api.deleteRole, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.deleteRoleSuccess(data));
  } catch (error) {
    yield put(actions.deleteRoleFail(_.get(error, 'message')));
  }
}

export function* getListRole(api) {
  const response = yield call(api.getListRole);
  try {
    const data = yield configResponse(response);
    yield put(actions.getListRoleSuccess(data));
  } catch (error) {
    yield put(actions.getListRoleFail(_.get(error, 'message')));
  }
}

export function* updatePermissionInRole(api, action) {
  const response = yield call(api.updatePermissionInRole, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.updatePermissionInRoleSuccess(data));
  } catch (error) {
    yield put(actions.updatePermissionInRoleFail(_.get(error, 'message')));
  }
}

export function* updateUsersInRole(api, action) {
  const response = yield call(api.updateUsersInRole, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.updateUsersInRoleSuccess(data));
  } catch (error) {
    yield put(actions.updateUsersInRoleFail(_.get(error, 'message')));
  }
}

export function* getListPermission(api) {
  const response = yield call(api.getListPermission);
  try {
    const data = yield configResponse(response);
    yield put(actions.getListPermissionSuccess(data));
  } catch (error) {
    yield put(actions.getListPermissionFail(_.get(error, 'message')));
  }
}

export function* deleteUser(api, action) {
  const response = yield call(api.deleteUser, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.deleteUserSuccess(data));
  } catch (error) {
    yield put(actions.deleteUserFail(_.get(error, 'message')));
  }
}

export function* changeUserStatus(api, action) {
  const response = yield call(api.changeUserStatus, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.changeUserStatusSuccess(data));
  } catch (error) {
    yield put(actions.changeUserStatusFail(_.get(error, 'message')));
  }
}

export function* createUser(api, action) {
  const response = yield call(api.createUser, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.createUserSuccess(data));
  } catch (error) {
    yield put(actions.createUserFail(_.get(error, 'message')));
  }
}

export function* updateUser(api, action) {
  const response = yield call(api.updateUser, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.updateUserSuccess(data));
  } catch (error) {
    yield put(actions.updateUserFail(_.get(error, 'message')));
  }
}

export function* checkPassWord(api, action) {
  const response = yield call(api.checkPassWord, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.checkPassWordSuccess(data));
  } catch (error) {
    yield put(actions.checkPassWordFail(_.get(error, 'message')));
  }
}

export function* updateProfileUser(api, action) {
  const response = yield call(api.updateProfileUser, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.updateProfileUserSuccess(data));
  } catch (error) {
    yield put(actions.updateProfileUserFail(_.get(error, 'message')));
  }
}

export function* checkUserName(api, action) {
  const response = yield call(api.checkUserName, action.payload);
  try {
    const data = yield configResponse(response);
    if (typeof data === 'boolean' && data) {
      return yield put(actions.useNameExist(data));
    }
  } catch (error) {
    yield put(actions.checkUserNameFail(_.get(error, 'message')));
  }
}

export function* checkUserCode(api, action) {
  const response = yield call(api.checkUserCode, action.payload);
  try {
    const data = yield configResponse(response);
    if (typeof data === 'boolean' && data) {
      return yield put(actions.useCodeExist(data));
    }
  } catch (error) {
    yield put(actions.checkUserCodeFail(_.get(error, 'message')));
  }
}

export function* validateUser(api, action) {
  const response = yield call(api.validateUser, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.validateUserSuccess(data));
  } catch (error) {
    yield put(actions.validateUserFail(_.get(error, 'message')));
  }
}

export function* getRoleById(api, action) {
  const response = yield call(api.getRoleById, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.getRoleByIdSuccess(data));
  } catch (error) {
    yield put(actions.getRoleByIdFail(_.get(error, 'message')));
  }
}

export function* AuthSaga() {
  yield all([
    yield takeLatest(actions.getUserById.type, getUserById, authRequest),
    yield takeLatest(actions.checkUserName.type, checkUserName, authRequest),
    yield takeLatest(actions.checkUserCode.type, checkUserCode, authRequest),
    yield takeLatest(actions.validateUser.type, validateUser, authRequest),
    yield takeLatest(
      actions.getUserByIdToken.type,
      getUserByIdToken,
      authRequest,
    ),
    yield takeLatest(actions.login.type, login, authRequest),
    yield takeLatest(actions.getListUser.type, getListUser, authRequest),
    yield takeLatest(actions.getListRole.type, getListRole, authRequest),
    yield takeLatest(actions.getRoleById.type, getRoleById, authRequest),
    yield takeLatest(actions.deleteRole.type, deleteRole, authRequest),
    yield takeLatest(actions.addRole.type, addRole, authRequest),
    yield takeLatest(
      actions.updatePermissionInRole.type,
      updatePermissionInRole,
      authRequest,
    ),
    yield takeLatest(
      actions.updateUsersInRole.type,
      updateUsersInRole,
      authRequest,
    ),
    yield takeLatest(
      actions.getListPermission.type,
      getListPermission,
      authRequest,
    ),
    yield takeLatest(actions.deleteUser.type, deleteUser, authRequest),
    yield takeLatest(
      actions.changeUserStatus.type,
      changeUserStatus,
      authRequest,
    ),
    yield takeLatest(actions.createUser.type, createUser, authRequest),
    yield takeLatest(actions.updateUser.type, updateUser, authRequest),
    yield takeLatest(actions.checkPassWord.type, checkPassWord, authRequest),
    yield takeLatest(
      actions.updateProfileUser.type,
      updateProfileUser,
      authRequest,
    ),
  ]);
}

class SagaHelper {
  static addUserRoute(user: any) {
      try {
          const authService = new PrivateAuthRoute(user);
          const userRoutes = authService.seperateRoute(AppRouting);
          const userInfo: any = {
              ...user,
              userRoutes,
          };
          return userInfo;
      } catch (err) {
          // Convert err to a string for the Error constructor
          const errorMessage = typeof err === 'string' ? err : JSON.stringify(err);
          throw new Error(errorMessage);
      }
  }
}