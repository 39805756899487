import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { TimeSlotHttp } from '../services/timeslot.http';
import { HttpResponse } from 'store/common/services/response.http';
import { actions } from './slice';
import { get } from 'lodash';

const http = new TimeSlotHttp();
const interceptHttp = new HttpResponse(http);

export function* getTimeSlotsSetting(api, action) {
  const response = yield call(api.getTimeSlotsSetting, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getTimeSlotsSettingSuccess(data));
  } catch (error) {
    yield put(actions.getTimeSlotsSettingFail(get(error, 'message')));
  }
}

export function* getTimeSlotExists(api, action) {
  const response = yield call(api.getTimeSlotExists, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getTimeSlotExistsSuccess(data));
  } catch (error) {
    yield put(actions.getTimeSlotExistsFail(get(error, 'message')));
  }
}

export function* getAllTimeSlots(api, action) {
  const response = yield call(api.getAllTimeSlots, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getAllTimeSlotsSuccess(data));
  } catch (error) {
    yield put(actions.getAllTimeSlotsFail(get(error, 'message')));
  }
}

export function* TimeSlotsSaga() {
  yield all([
    yield takeLatest(
      actions.getTimeSlotsSetting.type,
      getTimeSlotsSetting,
      http,
    ),
    yield takeLatest(actions.getTimeSlotExists.type, getTimeSlotExists, http),
    yield takeLatest(actions.getAllTimeSlots.type, getAllTimeSlots, http),
  ]);
}
