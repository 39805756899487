/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  useInjectReducer,
  useInjectSaga,
} from 'store/core/@reduxjs/redux-injectors';
import { useDispatch } from 'react-redux';
import * as AuthSlice from 'store/auth/shared/slice';
import * as CommonSlice from 'store/common/shared/slice';
import * as roomOnlineSlice from 'store/roomOnline/shared/slice';
import * as roomConfigSlice from 'store/roomConfig/shared/slice';
import * as timeSlotSlice from 'store/timeslots/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import { AuthSaga } from 'store/auth/shared/saga';
import { RoomConfigSaga } from 'store/roomConfig/shared/saga';
import { RoomOnlineSaga } from 'store/roomOnline/shared/saga';
import { TimeSlotsSaga } from 'store/timeslots/shared/saga';
import {
  RESPONSE_CONSTANT,
  RESPONSE_MESSAGE,
} from 'store/auth/constants/auth.constant';

/* Routing */
import { AppRouting } from 'router';
import { SnackbarProvider } from 'notistack';
import {
  LocalStorageService,
  LocalStorageKey,
} from 'services/localStorage.service';

/* Layout */
import { Header } from 'app/components/Header';
import { Breadcrumb } from 'app/components/Breadcrumb';
import { Footer } from 'app/components/Footer';
import * as _ from 'lodash';

/* Static Route */
import { LoginPage } from 'router/lazyRouting';

/* Animated */
import { AnimatedSwitch } from 'react-router-transition';
import { bounceTransition } from 'animation/AnimationSwitch';
import { AppHelper } from 'utils/app.helper';
import { Unsubscribe } from 'redux';
import { useSnackbar } from 'notistack';
import { NotifyService } from 'services/notify.service';
import RootStore from 'store/configureStore';
import { RegisterNumber } from './containers/RegisterNumber';

export function App() {
  const local = new LocalStorageService();
  useInjectReducer({ key: AuthSlice.sliceKey, reducer: AuthSlice.reducer });
  useInjectSaga({ key: AuthSlice.sliceKey, saga: AuthSaga });
  useInjectReducer({ key: CommonSlice.sliceKey, reducer: CommonSlice.reducer });
  useInjectSaga({ key: CommonSlice.sliceKey, saga: CommonSaga });
  useInjectReducer({
    key: roomOnlineSlice.sliceKey,
    reducer: roomOnlineSlice.reducer,
  });
  useInjectSaga({ key: roomOnlineSlice.sliceKey, saga: RoomOnlineSaga });
  useInjectReducer({
    key: roomConfigSlice.sliceKey,
    reducer: roomConfigSlice.reducer,
  });
  useInjectSaga({ key: roomConfigSlice.sliceKey, saga: RoomConfigSaga });
  useInjectReducer({
    key: timeSlotSlice.sliceKey,
    reducer: timeSlotSlice.reducer,
  });
  useInjectSaga({ key: timeSlotSlice.sliceKey, saga: TimeSlotsSaga });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activatedRoute = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const notifyController = new NotifyService(enqueueSnackbar);

  React.useEffect(() => {
    function validateToken() {
      // Check if the current route requires authentication
      const publicRoutes = ['/register-number', '/login']; // Add other public routes as needed
      if (publicRoutes.includes(location.pathname)) {
        return; // Skip validation for public routes
      }

      const tokenExpired: any = local.getItem(LocalStorageKey.tokenExpired);
      if (_.isEmpty(tokenExpired)) {
        return navigate('/login');
      }
      const isExpiredToken: boolean = local.expiredToken(
        JSON.parse(tokenExpired),
      );
      if (isExpiredToken) {
        navigate('/login');
        LocalStorageService.clear();
        return dispatch(
          AuthSlice.actions.setError({
            id: AppHelper.generateUUID() + Date.now(),
            key: RESPONSE_CONSTANT.TOKEN_EXPIRED,
            message: RESPONSE_MESSAGE.TOKEN_EXPIRED,
          }),
        );
      }
      const userValue: any = local.getItem(LocalStorageKey.user);
      const userId: any = JSON.parse(userValue);
      if (!_.isEmpty(userId)) {
        dispatch(AuthSlice.actions.getUserByIdToken(userId));
      }
    }

    function notifyAndRedirect({ type, payload }: { [x: string]: any }) {
      switch (type) {
        case AuthSlice.actions.getUserByIdTokenFail.type:
          navigate('/login');
          notifyController.setNotifyMessage(payload).setFailNotify();
          break;
        case AuthSlice.actions.getListPermissionFail.type:
        case AuthSlice.actions.getListRoleFail.type:
        case AuthSlice.actions.loginFail.type:
          notifyController.setNotifyMessage(payload).setFailNotify();
          dispatch(AuthSlice.actions.clearError());
          break;
        case AuthSlice.actions.loginSuccess.type:
          notifyController
            .setNotifyMessage(RESPONSE_MESSAGE.LOGIN_SUCCESS)
            .setSuccessNotify();
          const userRoutes: any[] = _.get(payload, 'userRoutes');
          local.setLocalUser(payload);
          // navigate(_.head(userRoutes).path);
          navigate('/switchboard');
          break;
        case AuthSlice.actions.updateProfileUserSuccess.type:
          notifyController
            .setNotifyMessage(
              RESPONSE_MESSAGE.UPDATE_PROFILE_USER_SUCCESS +
              'Vui lòng đăng nhập lại.',
            )
            .setSuccessNotify();
          dispatch(AuthSlice.actions.logout());
          navigate('/login');
          break;
        case AuthSlice.actions.getUserByIdTokenSucess.type:
          notifyController
            .setNotifyMessage(RESPONSE_MESSAGE.GET_USER_BY_ID_SUCCESS)
            .setSuccessNotify();
          const userByTokenRoutes: any[] = _.get(payload, 'userRoutes');
          const activeRoute = _.get(activatedRoute, 'pathname');
          const search = _.get(activatedRoute, 'search');
          const canActiveRoute = userByTokenRoutes
            .map(({ path }) => path)
            .some(route => {
              return route === activeRoute;
            });
          if (!canActiveRoute) {
            return navigate(_.head(userByTokenRoutes).path);
          }
          if (search === '') {
            return navigate(activeRoute);
          }
          return navigate(activeRoute + search);
      }
    }

    validateToken();

    // Dispatch initial data
    dispatch(timeSlotSlice.actions.getAllTimeSlots());
    dispatch(roomOnlineSlice.actions.getAllRoomsNotSetting());
    dispatch(roomOnlineSlice.actions.getAllRoomsSetting());
    dispatch(roomConfigSlice.actions.getAllRoomConfigNumber());
    dispatch(roomConfigSlice.actions.getAllRoomConfigNoNumber());
    dispatch(AuthSlice.actions.getListPermission());
    dispatch(AuthSlice.actions.getListRole());

    // Subscribe to store for notifications
    const storeSub$: Unsubscribe = RootStore.subscribe(() => {
      const { type, payload } = RootStore.getState().lastAction;
      notifyAndRedirect({ type, payload });
    });

    // Redirect to `register-number` when accessing `/`
    if (location.pathname === '/') {
      navigate('/register-number');
    }

    return () => {
      storeSub$();
    };
  }, [location.pathname]);

  const renderAppRouting = () => {
    return (
      <Routes>
        {AppRouting.map(({ path, name, Component, title, isStatic }) => (
          <Route
            key={name}
            path={path}
            element={
              <React.Fragment>
                <Helmet>
                  <title>{title}</title>
                  <meta
                    name="description"
                    content={`Tekmedi Admin - ${title}`}
                  />
                </Helmet>
                {!isStatic && <Breadcrumb />}
                <Component />
              </React.Fragment>
            }
          />
        ))}
      </Routes>
    );
  };

  // Helper to check if the user is authenticated
  const isAuthenticated = () => {
    const token = local.getItem(LocalStorageKey.tokenExpired);
    return !!token; // Return true if token exists
  };

  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transitionDuration={{ enter: 300, exit: 200 }}
      autoHideDuration={2000}
    >
      <Routes>
        {/* Conditional redirect for `/` */}
        <Route
          path="/"
          element={
            isAuthenticated() ? (
              <Navigate to="/register-number" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        {/* Public route: `/register-number` */}
        <Route
          path="/register-number"
          element={<RegisterNumber />} // Replace with your actual component
        />

        {/* Public route: `/login` */}
        <Route path="/login" element={<LoginPage />} />

        {/* Other routes */}
        <Route
          path="*"
          element={
            <div className="page-wrapper">
              <Header />
              <section className="content">
                <div className="page-container">
                  <div className="page-content-wrapper">
                    <div className="page-content">{renderAppRouting()}</div>
                  </div>
                </div>
                <Footer />
              </section>
            </div>
          }
        />
      </Routes>
    </SnackbarProvider>
  );
}

