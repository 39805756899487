export const PatientOnlineRoutes = {
  GET_ALL_STT: '/api/SwitchBoardDepartment/get/all',
  GET_PATIENTS_ONLINE: '/api/PatientOnline',
  GET_PATIENT_BY_PHONE: '/api/PatientOnline/phone',
  GET_PATIENT_BY_CODE: '/api/PatientOnline/get/patient-code',
  SYNC_PATIENT: '/api/PatientOnline/sync/online-patient',
  CANCEL_BOOKING: '/api/PatientOnline/cancel-examination',
  CHECK_IN: '/api/PatientOnline/check-in',
};

export const RESPONSE_CONSTANT = {
  SYNC_PATIENT_SUCCESS: 'SYNC_PATIENT_SUCCESS',
  SYNC_PATIENT_FAIL: 'SYNC_PATIENT_FAIL',
  CANCEL_BOOKING_SUCCESS: 'CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAIL: 'CANCEL_BOOKING_FAIL',
  CHECK_IN_SUCCESS: 'CHECK_IN_SUCCESS',
  CHECK_IN_FAIL: 'CHECK_IN_FAIL',
};

export const RESPONSE_MESSAGE = {
  SYNC_PATIENT_SUCCESS: 'Đồng bộ số thự tự online thành công',
  SYNC_PATIENT_FAIL: 'Đồng bộ số thự tự online thất bại',
  CANCEL_BOOKING_SUCCESS: 'Hủy đặt lịch thành công',
  CANCEL_BOOKING_FAIL: 'Hủy đặt lịch thất bại',
  CHECK_IN_SUCCESS: 'Check-In thành công',
  CHECK_IN_FAIL: 'Check-In thất bại',
};
