import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { PatientOnlineHttp } from '../services/checkin.http';
import { HttpResponse } from 'store/common/services/response.http';
import { actions } from './slice';
import { get } from 'lodash';

const http = new PatientOnlineHttp();
const interceptHttp = new HttpResponse(http);

export function* getPatients(api, action) {
  const response = yield call(api.getPatients, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getPatientsSuccess(data));
  } catch (error) {
    yield put(actions.getPatientsFail(get(error, 'message')));
  }
}

export function* getPatientByPhone(api, action) {
  const response = yield call(api.getPatientByPhone, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getPatientByPhoneSuccess(data));
  } catch (error) {
    yield put(actions.getPatientByPhoneFail(get(error, 'message')));
  }
}

export function* getPatientByCode(api, action) {
  const response = yield call(api.getPatientByCode, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getPatientByCodeSuccess(data));
  } catch (error) {
    yield put(actions.getPatientByCodeFail(get(error, 'message')));
  }
}

export function* syncPatient(api, action) {
  const response = yield call(api.syncPatient, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.syncPatientSuccess(data));
  } catch (error) {
    yield put(actions.syncPatientFail(get(error, 'message')));
  }
}

export function* cancelBooking(api, action) {
  const response = yield call(api.cancelBooking, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.cancelBookingSuccess(data));
  } catch (error) {
    yield put(actions.cancelBookingFail(get(error, 'message')));
  }
}

export function* checkIn(api, action) {
  const response = yield call(api.checkIn, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.checkInSuccess(data));
  } catch (error) {
    yield put(actions.checkInFail(get(error, 'message')));
  }
}

export function* PatientOnlineSaga() {
  yield all([
    yield takeLatest(actions.getPatients.type, getPatients, http),
    yield takeLatest(actions.getPatientByPhone.type, getPatientByPhone, http),
    yield takeLatest(actions.getPatientByCode.type, getPatientByCode, http),
    yield takeLatest(actions.syncPatient.type, syncPatient, http),
    yield takeLatest(actions.cancelBooking.type, cancelBooking, http),
    yield takeLatest(actions.checkIn.type, checkIn, http),
  ]);
}
