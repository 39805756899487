/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';

export function NavbarItem({ route }) {
  const activeRoute = route.childRoute[0];
  // if (route.childRoute.length === 1) {
  //   return (
  //     <li className="menu">
  //       <NavLink
  //         to={activeRoute.path}
  //         className="dropdown-toggle tek-menu-item"
  //         style={{ cursor: 'pointer' }}
  //       >
  //         <i className="material-icons">{activeRoute.icon}</i>
  //         {route.title}
  //       </NavLink>
  //     </li>
  //   );
  // }
  return (
    <li className="mega-menu-dropdown">
      <a className="dropdown-toggle tek-menu-item" href="#">
        <i className="material-icons">{activeRoute.categoryIcon}</i>
        {route.title}
        <i className="fa fa-angle-down"></i>
      </a>
      <ul className="dropdown-menu pull-left">
        <div className="dropdown-submenu">
          {route.childRoute.map((itemMapping: any, index: number) => {
            return (
              <li className="nav-item" key={index}>
                <NavLink
                  to={itemMapping.path}
                  className="nav-link tek-menu-item"
                >
                  <i className="material-icons">{itemMapping.icon}</i>
                  <span className="title">{itemMapping.title}</span>
                </NavLink>
              </li>
            );
          })}
        </div>
      </ul>
    </li>
  );
}
