/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const examState = (state: RootState) => state.exam || initialState;

export const selectLoading = createSelector([examState], exam => exam.loading);

export const selectSuccess = createSelector([examState], exam => exam.success);

export const selectError = createSelector([examState], exam => exam.error);
