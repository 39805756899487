import { HttpRequest, serializeQuery } from 'utils/request';
import { APP_CONSTANT } from 'store/common/constants';
import { CommonHttp } from 'store/common/services/common.http';
import { PatientOnlineRoutes } from '../constants';
import { AppHelper } from 'utils/app.helper';

export interface Board {
  id?: string;
  name: string;
  code: string;
  isActive?: boolean;
}

export interface TimeSlotRequest {
  departmentIds: string[];
  totalNumber: number;
  timeSlots: any[];
}

export interface ExportFilter {
  length: number;
  actives: boolean[];
}
export class PatientOnlineHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  public getPatients = ({
    startDate,
    endDate,
    ...filter
  }: any): Promise<any> => {
    const formatFilter: any = {
      ...CommonHttp.convertRequest(filter),
      start_date: AppHelper.formatDateAPI(startDate),
      end_date: AppHelper.formatDateAPI(endDate),
    };
    const queryString = serializeQuery(formatFilter);
    return this.request.get(
      `${PatientOnlineRoutes.GET_PATIENTS_ONLINE}?${queryString}`,
    );
  };

  public getPatientByPhone = (phone: string): Promise<any> => {
    return this.request.get(
      `${PatientOnlineRoutes.GET_PATIENT_BY_PHONE}/${phone}`,
    );
  };

  public getPatientByCode = (code: string): Promise<any> => {
    return this.request.get(
      `${PatientOnlineRoutes.GET_PATIENT_BY_CODE}/${code}`,
    );
  };

  public syncPatient = (registerDate: any): Promise<any> => {
    return this.request.post(PatientOnlineRoutes.SYNC_PATIENT, {
      register_Date: AppHelper.formatDateAPI(registerDate),
    });
  };

  public cancelBooking = (bookingCode: any): Promise<any> => {
    return this.request.post(PatientOnlineRoutes.CANCEL_BOOKING, {
      booking_code: bookingCode,
    });
  };

  public checkIn = (bookingCode: any): Promise<any> => {
    return this.request.post(PatientOnlineRoutes.CHECK_IN, {
      booking_code: bookingCode,
    });
  };
}
