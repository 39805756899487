/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const patientOnlineState = (state: RootState) =>
  state.patientOnline || initialState;

export const selectLoading = createSelector(
  [patientOnlineState],
  patientOnline => patientOnline.loading,
);

export const selectSuccess = createSelector(
  [patientOnlineState],
  patientOnline => patientOnline.success,
);

export const selectError = createSelector(
  [patientOnlineState],
  patientOnline => patientOnline.error,
);

export const selectPatients = createSelector(
  [patientOnlineState],
  patientOnline => patientOnline.patients,
);

export const selectPatient = createSelector(
  [patientOnlineState],
  patientOnline => patientOnline.patient,
);
