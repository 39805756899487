/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from 'react';
import * as commonSelectors from 'store/common/shared/selectors';
import * as roomConfigSelectors from 'store/roomConfig/shared/selectors';
import * as patientSelectors from 'store/patientOnline/shared/selectors';
import * as timeSlotSelectors from 'store/timeslots/shared/selectors';
import * as examSelectors from 'store/examination/shared/selectors';
import * as commonSlice from 'store/common/shared/slice';
import * as examSlice from 'store/examination/shared/slice';
import * as patientSlice from 'store/patientOnline/shared/slice';
import * as timeSlotSlice from 'store/timeslots/shared/slice';
import * as _ from 'lodash';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
  RESPONSE_CONSTANT,
  RESPONSE_MESSAGE,
} from 'store/examination/constants';
import { CommonSaga } from 'store/common/shared/saga';
import { ExamSaga } from 'store/examination/shared/saga';
import { PatientOnlineSaga } from 'store/patientOnline/shared/saga';
import { TimeSlotsSaga } from 'store/timeslots/shared/saga';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Button,
  Modal,
  Result,
  message,
} from 'antd';
import { MOMENT_RENDER } from 'store/common/constants';
import { AppHelper } from 'utils/app.helper';
import { CommonHttp } from 'store/common/services/common.http';
import { LocationService } from 'services/locations.service';
import { NotifyService } from 'services/notify.service';
import { useSnackbar } from 'notistack';
import { CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import locale from 'antd/es/date-picker/locale/vi_VN';
import 'moment/locale/vi';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';

interface Examination {
  switchBoardId: string;
  firstName: string;
  lastName: string;
  gender: number;
  patientCode: string;
  phoneNumber: string;
  hospitalId: string;
  timeSlotId: string;
  departmentId: string;
  registeredDate: string;
  street: string;
  provinceCode: string;
  districtCode: string;
  wardCode: string;
  dateOfBirth: string;
  birthDay: string;
  yearOfBirth: string;
  identityNumber: string;
  passport: string;
  identityNumberIssueDate: string;
  identityNumberIssuePlace: string;
  specialistCode: string;
  patientType: string;
}

interface ModalAction {
  confirm: boolean;
  confirmCancel: boolean;
  successRegister: boolean;
}

interface Action {
  payload: any;
  type: ActionType;
}

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  padding-bottom: 120px;
`;

const RequiredText = styled.p`
  font-size: 14px;
  color: #dc1111;
  margin: 0;
  padding: 0;
`;

const TitleText = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  padding: 10px 0;
  color: #000;
  text-transform: uppercase;
`;

const ConfirmInfoContent = styled.div<any>`
  display: flex;
`;

const ConfirmTitle = styled.p`
  margin: 0;
  padding: 0;
  color: #828282;
  font-size: 16px;
  width: 150px;
`;

const ConfirmInfo = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: #4f4f4f;
  flex: 1;
`;

const SuccessFooter = styled.div`
  display: block;
  padding-top: 24px;
`;

const SuccessOrder = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    margin: 0;
    padding: 0;
  }
`;

const SuccessOrderText = styled.p`
  color: #666666;
  font-size: 16px;
  font-weight: 600;
`;

const SuccessOrderNumber = styled.p`
  color: #000;
  font-size: 32px;
  font-weight: 600;
`;

const CancelText = styled.p`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

const { Option } = Select;

enum ActionType {
  switchBoardId = 'switchBoardId',
  firstName = 'firstName',
  lastName = 'lastName',
  gender = 'gender',
  patientCode = 'patientCode',
  phoneNumber = 'phoneNumber',
  hospitalId = 'hospitalId',
  timeSlotId = 'timeSlotId',
  departmentId = 'departmentId',
  registeredDate = 'registeredDate',
  street = 'street',
  provinceCode = 'provinceCode',
  districtCode = 'districtCode',
  wardCode = 'wardCode',
  dateOfBirth = 'dateOfBirth',
  birthDay = 'birthDay',
  yearOfBirth = 'yearOfBirth',
  identityNumber = 'identityNumber',
  passport = 'passport',
  identityNumberIssueDate = 'identityNumberIssueDate',
  identityNumberIssuePlace = 'identityNumberIssuePlace',
  specialistCode = 'specialistCode',
  patientType = 'patientType',
  reset = 'reset',
  add = 'add',
  note = 'note',
}

const defaultState: any = {
  switchBoardId:
    '6df8b493-ad4c-46bf-bd0a-e3e839781853' /* Hardcode SWITCHBOARD ID*/,
  firstName: '',
  lastName: '',
  gender: 1,
  patientCode: '',
  phoneNumber: '',
  hospitalId:
    'c8c38785-75c4-11ea-baaa-43f248aa8bcc' /* Hardcode default BVK ID*/,
  timeSlotId: '',
  departmentId: '',
  registeredDate: '',
  street: '',
  provinceCode: '',
  districtCode: '',
  wardCode: '',
  dateOfBirth: '',
  birthDay: '',
  yearOfBirth: '',
  identityNumber: '',
  passport: '',
  identityNumberIssueDate: '',
  identityNumberIssuePlace: '',
  specialistCode: '',
  patientType: '',
  note: '',
};

const reducer = (state, { payload, type }: Action): Examination => {
  switch (type) {
    case ActionType.reset:
      return defaultState;
    case ActionType.add:
      const { dateOfBirth } = payload;
      return {
        ...state,
        ...payload,
        yearOfBirth: dateOfBirth.eraYear(),
        birthDay: dateOfBirth,
      };
    default:
      return state;
  }
};

export function RegisterNumber(props: any) {
  useInjectReducer({ key: commonSlice.sliceKey, reducer: commonSlice.reducer });
  useInjectSaga({ key: commonSlice.sliceKey, saga: CommonSaga });
  useInjectReducer({
    key: patientSlice.sliceKey,
    reducer: patientSlice.reducer,
  });
  useInjectSaga({ key: patientSlice.sliceKey, saga: PatientOnlineSaga });
  useInjectReducer({
    key: examSlice.sliceKey,
    reducer: examSlice.reducer,
  });
  useInjectSaga({ key: examSlice.sliceKey, saga: ExamSaga });
  useInjectReducer({
    key: timeSlotSlice.sliceKey,
    reducer: timeSlotSlice.reducer,
  });
  useInjectSaga({ key: timeSlotSlice.sliceKey, saga: TimeSlotsSaga });
  const activatedRoute = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const notifyController = new NotifyService(enqueueSnackbar);
  const locations = useSelector(commonSelectors.selectLocations);
  const commonLoading = useSelector(commonSelectors.selectLoading);
  const configCompleted = useSelector(
    roomConfigSelectors.selectRoomConfigsNumber,
  );
  const timeSlotExists = useSelector(timeSlotSelectors.selectTimeSlotsExist);
  const success = useSelector(examSelectors.selectSuccess);
  const examLoading = useSelector(examSelectors.selectLoading);
  const error = useSelector(patientSelectors.selectError);
  const errorExam = useSelector(examSelectors.selectError);
  const patient = useSelector(patientSelectors.selectPatient);
  const dispatch = useDispatch();
  const [exam, dispatchExam] = useReducer(reducer, defaultState);
  const [canCancel, setCanCancel] = useState<boolean>(false);
  const [province, setProvince] = useState<any>({});
  const [dist, setDist] = useState<any>({});
  const [form] = Form.useForm();
  const [modal, setModal] = useState<ModalAction>({
    confirm: false,
    confirmCancel: false,
    successRegister: false,
  });

  useEffect(() => {
    dispatch(commonSlice.actions.getLocations());
    return () => {
      dispatch(patientSlice.actions.clearPatient());
      dispatch(patientSlice.actions.clearSuccess());
      dispatch(examSlice.actions.clearSuccess());
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(activatedRoute) && activatedRoute.search) {
      const { phone }: any = AppHelper.getParamsFromUrl(activatedRoute.search);
      if (!AppHelper.checkEmptyString(phone)) {
        setCanCancel(true);
        dispatch(patientSlice.actions.getPatientByPhone(phone));
      }
    }
  }, [activatedRoute]);

  useEffect(() => {
    function renderMessage(isLoad: boolean, success) {
      let content: string = 'Loaded!';
      if (isLoad) {
        return message.loading({
          content: 'Đang tải dữ liệu..',
          key: 'updatable',
        });
      }
      if (!_.isEmpty(success)) {
        if (success.key === RESPONSE_CONSTANT.REGISTER_EXAM_SUCCESS) {
          setModal({
            ...modal,
            confirm: false,
            successRegister: true,
          });
          content = RESPONSE_MESSAGE.REGISTER_EXAM_SUCCESS;
        } else if (success.key === RESPONSE_CONSTANT.CANCEL_EXAM_SUCCESS) {
          setModal({
            ...modal,
            confirmCancel: false,
            successRegister: false,
          });
          setCanCancel(false);
          content = RESPONSE_MESSAGE.CANCEL_EXAM_SUCCESS;
          dispatch(examSlice.actions.clearSuccess());
          dispatch(patientSlice.actions.clearPatient());
        }
        message.success({ content, key: 'updatable', duration: 2 });
        form.resetFields();
        dispatchExam({
          type: ActionType.reset,
          payload: undefined,
        });
        navigate({ ...location, search: '' }, { replace: true });
      }
    }
    renderMessage(examLoading, success);
  }, [success, examLoading]);

  useEffect(() => {
    if (!_.isEmpty(error) && error.message) {
      notifyController.setNotifyMessage(error.message).setFailNotify();
      form.resetFields();
      dispatch(patientSlice.actions.clearError());
    }
    if (!_.isEmpty(errorExam) && errorExam.message) {
      notifyController.setNotifyMessage(errorExam.message).setFailNotify();
      dispatch(examSlice.actions.clearError());
    }
  }, [error, errorExam]);

  useEffect(() => {
    function setDefaultValue() {
      if (
        _.isEmpty(patient) ||
        _.isEmpty(activatedRoute) ||
        activatedRoute.search === ''
      ) {
        setCanCancel(false);
        return;
      }
      const {
        firstName,
        provinceId,
        districtName,
        wardName,
        departmentId,
        departmentName,
        gender,
        street,
        phone,
        birthday,
        registeredDate,
        identityCardNumber,
        note,
      } = CommonHttp.convertResponse(patient);
      form.setFieldsValue({
        [ActionType.phoneNumber]: phone,
        [ActionType.firstName]: firstName,
        [ActionType.provinceCode]: provinceId,
        [ActionType.districtCode]: districtName,
        [ActionType.wardCode]: wardName,
        [ActionType.departmentId]: departmentId ? departmentId : departmentName,
        [ActionType.gender]: gender,
        [ActionType.street]: street,
        [ActionType.dateOfBirth]: moment(birthday),
        [ActionType.registeredDate]: moment(registeredDate),
        [ActionType.identityNumber]: identityCardNumber,
        [ActionType.note]: note,
      });
      setCanCancel(true);
    }
    setDefaultValue();
  }, [patient, activatedRoute]);

  const getTimeSlot = () => {
    const registeredDate = form.getFieldValue([ActionType.registeredDate]);
    const departmentId = form.getFieldValue([ActionType.departmentId]);
    if (registeredDate === '' || !departmentId || departmentId === '') return;
    dispatch(
      timeSlotSlice.actions.getTimeSlotExists({ registeredDate, departmentId }),
    );
  };

  const handleSubmit = (values: any) => {
    if (!_.isEmpty(values) && !canCancel) {
      dispatchExam({
        type: ActionType.add,
        payload: values,
      });
      return setModal({
        ...modal,
        confirm: true,
      });
    }
    return setModal({
      ...modal,
      confirm: false,
      confirmCancel: true,
    });
  };

  return (
    <Wrapper>
      <Row gutter={16}>
        <Col xs={24} xl={24}>
          <RequiredText>* Thông tin bắt buộc điền</RequiredText>
        </Col>
        <Col xs={24} xl={24}>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={{
              remember: true,
              [ActionType.registeredDate]: moment()
                .add(+3, 'days')
                .endOf('day'),
            }}
            onFinish={handleSubmit}
            scrollToFirstError={true}
          >
            <Row gutter={16}>
              <Col xs={24} xl={24}>
                <TitleText>1.Thông tin bệnh nhân</TitleText>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Số điện thoại đăng kí"
                  name={ActionType.phoneNumber}
                  rules={[
                    { required: true, message: 'Số điện thoại đăng kí!' },
                    {
                      pattern: /^(?:\d*)$/,
                      message: 'Số điện thoại đăng kí phải là số',
                    },
                    {
                      pattern: /^[\d]{0,10}$/,
                      message: 'Số điện thoại đăng kí phải có 10 số',
                    },
                  ]}
                >
                  <Input.Search
                    allowClear={true}
                    type="number"
                    placeholder="Số điện thoại đăng kí!"
                    value={exam.phoneNumber}
                    onSearch={(value: any) => {
                      if (value === '') return;
                      dispatch(patientSlice.actions.getPatientByPhone(value));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Họ và tên bệnh nhân"
                  name={ActionType.firstName}
                  rules={[{ required: true, message: 'Họ và tên bệnh nhân' }]}
                >
                  <Input placeholder="Họ và tên bệnh nhân" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="CMND/CCCD"
                  name={ActionType.identityNumber}
                  rules={[
                    { required: true, message: 'CMND/CCCD' },
                    {
                      pattern: /^(?:\d*)$/,
                      message: 'Số CMND/CCCD đăng kí phải là số',
                    },
                  ]}
                >
                  <Input type="number" placeholder="CMND/CCCD" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Mã bệnh nhân (nếu có)"
                  name={ActionType.patientCode}
                >
                  <Input placeholder="Mã bệnh nhân (nếu có)" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Ngày sinh"
                  name={ActionType.dateOfBirth}
                  {...{
                    rules: [
                      {
                        type: 'object' as const,
                        message: 'Chọn ngày sinh',
                      },
                      { required: true, message: 'Chọn ngày sinh' },
                    ],
                  }}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    format={(value: any) => value.format(MOMENT_RENDER)}
                    locale={locale}
                    placeholder="Chọn ngày sinh"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Giới tính"
                  name={ActionType.gender}
                  // rules={[{ required: true, message: 'Chọn giới tính' }]}
                >
                  <Radio.Group value={exam.gender} defaultValue={1}>
                    <Radio value={1}>Nam</Radio>
                    <Radio value={0}>Nữ</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Tỉnh/Thành phố"
                  name={ActionType.provinceCode}
                >
                  <Select
                    placeholder="Tỉnh/Thành phố"
                    allowClear
                    showSearch
                    size="large"
                    loading={commonLoading}
                    filterOption={AppHelper.filterOption}
                    onSelect={(value: any) => {
                      form.setFieldsValue({
                        [ActionType.districtCode]: null,
                        [ActionType.wardCode]: null,
                      });
                      const province: any = locations.find(
                        loc => loc.province_code === value,
                      );
                      if (province) {
                        setProvince(province);
                      }
                    }}
                  >
                    {locations.length &&
                      locations.map((loc, idx) => {
                        return (
                          <Option key={idx} value={loc.province_code}>
                            {loc.province_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Quận/Huyện"
                  name={ActionType.districtCode}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Quận/Huyện"
                    size="large"
                    filterOption={AppHelper.filterOption}
                    onChange={(value: any) => {
                      form.setFieldsValue({
                        [ActionType.wardCode]: null,
                      });
                      const distSelect: any = province.districts.find(
                        dis => dis.district_code === value,
                      );
                      if (distSelect) {
                        setDist(distSelect);
                      }
                    }}
                  >
                    {!_.isEmpty(province) &&
                      province.districts.length &&
                      province.districts.map((dist, idx) => {
                        return (
                          <Option key={idx} value={dist.district_code}>
                            {dist.district_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Phường/Xã"
                  name={ActionType.wardCode}
                >
                  <Select
                    placeholder="Phường/Xã"
                    allowClear
                    size="large"
                    filterOption={AppHelper.filterOption}
                  >
                    {!_.isEmpty(dist) &&
                      dist.wards.length &&
                      dist.wards.map((ward, idx) => (
                        <Option key={idx} value={ward.ward_code}>
                          {ward.ward_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={16}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label=" Số nhà, tên đường, ngõ, ngách"
                  name={ActionType.street}
                >
                  <Input placeholder="Số nhà, tên đường, ngõ, ngách" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={16}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label=" Vấn đề cần khám"
                  name={ActionType.note}
                >
                  <TextArea rows={4} placeholder="Vấn đề cần khám" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '30px' }}>
              <Col xs={24} xl={24}>
                <TitleText>2.đăng kí sỐ THỨ TỰ online</TitleText>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Bệnh viện"
                  name={ActionType.hospitalId}
                >
                  <Input
                    placeholder="Bệnh Viện Đại Học Y Vinh"
                    value="Bệnh viện"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Phòng khám/Chuyên khoa khám"
                  name={ActionType.departmentId}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Phòng khám/Chuyên khoa khám"
                    size="large"
                    filterOption={AppHelper.filterOption}
                    onChange={value => getTimeSlot()}
                  >
                    {configCompleted &&
                      configCompleted.length &&
                      AppHelper.formatListFlat(configCompleted).map(
                        (room, idx) => {
                          return (
                            <Option value={room.id} key={idx}>
                              {room.description}
                            </Option>
                          );
                        },
                      )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Ngày khám "
                  name={ActionType.registeredDate}
                  {...{
                    rules: [
                      {
                        type: 'object' as const,
                        message: 'Chọn Ngày khám ',
                      },
                    ],
                  }}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    disabledDate={(current: any) => {
                      return (
                        current &&
                        current <
                          moment()
                            .add(+0, 'days')
                            .endOf('day')
                      );
                    }}
                    size="large"
                    locale={locale}
                    placeholder="Chọn ngày khám"
                    format={MOMENT_RENDER}
                    onChange={(date, dateString) => getTimeSlot()}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  label="Khung giờ khám"
                  name={ActionType.timeSlotId}
                >
                  <Select
                    placeholder="00:00 - 00:00 | số lượt trống trong khung giờ"
                    allowClear
                    size="large"
                    onChange={(value: any) => {
                      dispatchExam({
                        type: ActionType.timeSlotId,
                        payload: value,
                      });
                    }}
                  >
                    {timeSlotExists &&
                      timeSlotExists.list_number_online_json.length &&
                      timeSlotExists.list_number_online_json.map(
                        (time, idx) => {
                          return (
                            <Option value={time.id} key={idx}>
                              {AppHelper.formatTimeSlots(time, [
                                'start_time',
                                'end_time',
                              ])}{' '}
                              | {time?.number_not_used} lượt trống
                            </Option>
                          );
                        },
                      )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '30px' }}>
              <Col xs={24} xl={24}>
                <Form.Item style={{ textAlign: 'center' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ fontWeight: 600 }}
                    danger={canCancel}
                  >
                    {canCancel ? 'HUỶ ĐĂNG KÝ KHÁM' : 'ĐĂNG KÍ'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {modal.confirm && (
        <Modal
          title="Xác nhận thông tin"
          centered
          visible={modal.confirm}
          width={860}
          bodyStyle={{ padding: '30px 48px' }}
          onCancel={() =>
            setModal({
              ...modal,
              confirm: false,
            })
          }
          footer={[
            <Button
              key="cancel"
              type="primary"
              htmlType="button"
              style={{
                fontWeight: 600,
                background: '#E0E0E0',
                color: '#828282',
              }}
              onClick={() =>
                setModal({
                  ...modal,
                  confirm: false,
                })
              }
            >
              CHỈNH SỬA THÔNG TIN
            </Button>,
            <Button
              key="register"
              type="primary"
              htmlType="button"
              style={{ fontWeight: 600 }}
              onClick={() => dispatch(examSlice.actions.registerExam(exam))}
            >
              ĐĂNG KÝ KHÁM
            </Button>,
          ]}
        >
          <Row gutter={[0, 24]}>
            <Col xs={24} xl={16}>
              <RequiredText>
                Tổng đài viên vui lòng kiểm tra và xác nhận thông tin bệnh nhân
                và thông tin đăng kí khám với khách hàng trước khi bấm nút “ĐĂNG
                KÍ”
              </RequiredText>
            </Col>
            <Col xs={24} xl={24}>
              <TitleText>Thông tin bệnh nhân</TitleText>
              <Row gutter={[24, 16]}>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Số điện thoại</ConfirmTitle>
                    <ConfirmInfo>{exam?.phoneNumber}</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Ngày sinh</ConfirmTitle>
                    <ConfirmInfo>
                      {moment(exam?.dateOfBirth).format(MOMENT_RENDER)}
                    </ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Họ và tên BN </ConfirmTitle>
                    <ConfirmInfo>{exam?.firstName}</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Giới tính</ConfirmTitle>
                    <ConfirmInfo>{exam?.gender ? 'Nam' : 'Nữ'}</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>CMND/CCCD</ConfirmTitle>
                    <ConfirmInfo>{exam?.identityNumber}</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Địa chỉ</ConfirmTitle>
                    <ConfirmInfo>
                      {LocationService.findAndFormatAddress(locations, exam)}
                    </ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Mã bệnh nhân </ConfirmTitle>
                    <ConfirmInfo>{exam?.patientCode}</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={24}>
              <TitleText>Thông tin đăng ký </TitleText>
              <Row gutter={[24, 16]}>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Bệnh viện</ConfirmTitle>
                    {/* <ConfirmInfo>Bệnh viện Chợ Rẫy</ConfirmInfo> */}
                    <ConfirmInfo>BỆNH VIỆN ĐẠI HỌC Y KHOA VINH</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Ngày khám</ConfirmTitle>
                    <ConfirmInfo>
                      {moment(exam?.registeredDate).format(MOMENT_RENDER)}
                    </ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={12}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Phòng khám</ConfirmTitle>
                    <ConfirmInfo>Nội Tổng Quát</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      )}
      {!_.isEmpty(success) && modal.successRegister && (
        <Modal
          visible={modal.successRegister}
          width={420}
          closable={false}
          footer={null}
        >
          <Result
            status="success"
            extra={[
              <Row gutter={[16, 16]} key="success">
                <Col xs={24} xl={24}>
                  <SuccessOrder>
                    <SuccessOrderText>Số thứ tự</SuccessOrderText>
                    <SuccessOrderNumber>
                      {success?.data?.number}
                    </SuccessOrderNumber>
                  </SuccessOrder>
                </Col>
                <Col xs={24} xl={24}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Phòng khám</ConfirmTitle>
                    <ConfirmInfo>{success?.data?.department_name}</ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={24}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Ngày đăng kí khám</ConfirmTitle>
                    <ConfirmInfo>
                      {moment(success?.data?.registered_date).format(
                        MOMENT_RENDER,
                      )}
                    </ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={24}>
                  <ConfirmInfoContent>
                    <ConfirmTitle>Giờ khám dự kiến</ConfirmTitle>
                    <ConfirmInfo>
                      {!_.isEmpty(success) &&
                        AppHelper.formatTimeSlots(success?.data, [
                          'start_expected',
                          'end_expected',
                        ])}
                    </ConfirmInfo>
                  </ConfirmInfoContent>
                </Col>
                <Col xs={24} xl={24}>
                  <RequiredText>
                    Quý khách vui lòng đến trước 30 phút so với khung giờ đã
                    đăng kí để làm thủ tục khám bệnh
                  </RequiredText>
                </Col>
              </Row>,
              <SuccessFooter key="back">
                <Link key="linkList" to={`/switchboard`}>
                  Xem danh sách BN đã đăng kí khám
                </Link>
                <Button
                  key="reRegister"
                  onClick={() => {
                    dispatch(examSlice.actions.clearSuccess());
                    setModal({
                      ...modal,
                      successRegister: false,
                      confirm: false,
                    });
                    dispatchExam({
                      type: ActionType.reset,
                      payload: undefined,
                    });
                    form.resetFields();
                  }}
                  type="default"
                  htmlType="button"
                  style={{
                    fontWeight: 600,
                    color: '#1686dd',
                    textTransform: 'uppercase',
                    padding: '10px 15px',
                    marginTop: '20px',
                    lineHeight: 'initial',
                    borderColor: '#1686dd',
                    height: 'auto',
                  }}
                >
                  ĐĂNG KÍ CHO BN MỚI
                </Button>
              </SuccessFooter>,
            ]}
          />
        </Modal>
      )}
      {modal.confirmCancel && (
        <Modal
          visible={modal.confirmCancel}
          title="HỦY LỊCH KHÁM"
          width={420}
          closeIcon={<CloseCircleFilled />}
          closable={true}
          footer={[
            <Button
              key="noCancel"
              onClick={() => {
                setModal({
                  ...modal,
                  confirmCancel: false,
                });
              }}
              type="primary"
              danger
              htmlType="button"
              style={{
                background: '#e0e0e0',
                fontWeight: 600,
                color: '#828282',
                textTransform: 'uppercase',
                padding: '10px 15px',
                marginTop: '20px',
                lineHeight: 'initial',
                borderColor: '#e0e0e0',
                height: 'auto',
                boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.1)',
              }}
            >
              KHÔNG
            </Button>,
            <Button
              onClick={() => {
                dispatch(
                  examSlice.actions.cancelExam(_.get(patient, 'booking_code')),
                );
              }}
              key="okCancel"
              type="primary"
              danger
              htmlType="button"
              style={{
                background: '#dc1111',
                fontWeight: 600,
                color: '#fff',
                textTransform: 'uppercase',
                padding: '10px 15px',
                marginTop: '20px',
                lineHeight: 'initial',
                borderColor: '#dc1111',
                height: 'auto',
              }}
            >
              CÓ
            </Button>,
          ]}
        >
          <Row gutter={[0, 16]}>
            <Col xs={24} xl={24}>
              <CancelText>Bạn có chắc chắn muốn hủy lịch khám này?</CancelText>
              <CancelText>Dữ liệu sẽ không thể hoàn tác.</CancelText>
            </Col>
          </Row>
        </Modal>
      )}
    </Wrapper>
  );
}
