import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { ExamHttp } from '../services/exam.http';
import { HttpResponse } from 'store/common/services/response.http';
import { actions } from './slice';
import { get } from 'lodash';

const http = new ExamHttp();
const interceptHttp = new HttpResponse(http);

export function* registerExam(api, action) {
  const response = yield call(api.registerExam, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.registerExamSuccess(data));
  } catch (error) {
    yield put(actions.registerExamFail(get(error, 'message')));
  }
}

export function* cancelExam(api, action) {
  const response = yield call(api.cancelExam, action.payload);
  try {
    const data: any = interceptHttp.getGeneralApiResponse(response);
    yield put(actions.cancelExamSuccess(data));
  } catch (error) {
    yield put(actions.cancelExamFail(get(error, 'message')));
  }
}

export function* ExamSaga() {
  yield all([
    yield takeLatest(actions.registerExam.type, registerExam, http),
    yield takeLatest(actions.cancelExam.type, cancelExam, http),
  ]);
}
