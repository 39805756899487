/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest, serializeQuery } from 'utils/request';
import { AuthApiRoutes } from '../constants/auth.constant';
import { APP_CONSTANT, DEFAULT_ID } from 'store/common/constants';
import { CommonHttp } from 'store/common/services/common.http';
import * as _ from 'lodash';
export class AuthHttp {
  request: any;
  endPoint: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
    this.endPoint = endPoint;
  }

  login = ({ username, password }): Promise<any> => {
    return this.request.post(AuthApiRoutes.LOGIN, {
      user_name: username,
      password,
    });
  };

  getListUser = (filter: any): Promise<any> => {
    const formatFilter: any = CommonHttp.convertRequest(filter);
    const queryString = serializeQuery(formatFilter);
    return this.request.get(`${AuthApiRoutes.API_USER}?${queryString}`);
  };

  getUserByIdToken = (userId: string): Promise<any> => {
    return this.request.get(AuthApiRoutes.API_USER + '/' + userId);
  };

  getUserById = (id: string): Promise<any> => {
    return this.request.get(AuthApiRoutes.API_USER + '/' + id);
  };

  createUser = (personnelInfo: any): Promise<any> => {
    return this.request.post(AuthApiRoutes.CREATE_PERSONNEL, personnelInfo);
  };

  deleteUser = (id: string): Promise<any> => {
    return this.request.delete(`${AuthApiRoutes.API_USER}/${id}`);
  };

  changeUserStatus = ({ id, isActive }): Promise<any> => {
    return this.request.post(AuthApiRoutes.CHANGE_ACTIVE, {
      Id: id,
      IsActive: isActive,
    });
  };

  updateUser = (personal): Promise<any> => {
    return this.request.put(
      AuthApiRoutes.API_USER + '?id=' + personal.id,
      personal,
    );
  };

  addRole = (role: any): Promise<any> => {
    return this.request.post(AuthApiRoutes.API_ROLE, role);
  };

  deleteRole = (roleId: any): Promise<any> => {
    return this.request.delete(AuthApiRoutes.API_ROLE + '/' + roleId);
  };

  getListRole = (): Promise<any> => {
    return this.request.get(AuthApiRoutes.GET_LIST_ROLE);
  };

  getRoleById = (id: string): Promise<any> => {
    return this.request.get(AuthApiRoutes.GET_LIST_ROLE + '/' + id);
  };

  updatePermissionInRole = ({ roleId, listPermit }): Promise<any> => {
    return this.request.put(
      AuthApiRoutes.UPDATE_PERMISSION_IN_ROLE + '/' + roleId,
      {
        permission_ids: listPermit,
      },
    );
  };

  updateUsersInRole = ({ roleId, updateUser }): Promise<any> => {
    return this.request.put(AuthApiRoutes.UPDATE_USER_IN_ROLE + '/' + roleId, {
      ...CommonHttp.convertRequest(updateUser),
    });
  };

  getListPermission = (): Promise<any> => {
    return this.request.get(AuthApiRoutes.GET_LIST_PERMISSION);
  };

  checkUserName = (userName: string): Promise<any> => {
    return this.request.post(AuthApiRoutes.CHECK_USER_NAME_PERSONNEL, {
      id: DEFAULT_ID,
      user_name: userName,
    });
  };

  checkUserCode = (userCode: string): Promise<any> => {
    return this.request.post(AuthApiRoutes.CHECK_CODE_PERSONNEL, {
      Code: userCode,
    });
  };

  validateUser = ({
    userName,
    userCode,
  }: {
    [x: string]: string;
  }): Promise<any> => {
    return Promise.all([
      this.checkUserName(userName),
      this.checkUserCode(userCode),
    ]);
  };

  checkPassWord = ({ userName, passWord }): Promise<any> => {
    return this.request.post(AuthApiRoutes.CHECK_PASSWORD, {
      Password: passWord,
      UserName: userName,
    });
  };

  updateProfileUser = (user: any): Promise<any> => {
    return this.request.put(
      AuthApiRoutes.UPDATE_PROFILE_USER + '/' + user.id,
      user,
    );
  };
}
