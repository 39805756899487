/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const timeSlotsState = (state: RootState) => state.timeslots || initialState;

export const selectLoading = createSelector(
  [timeSlotsState],
  time => time.loading,
);

export const selectSuccess = createSelector(
  [timeSlotsState],
  time => time.success,
);

export const selectError = createSelector([timeSlotsState], time => time.error);

export const selectTimeSlotsSetting = createSelector(
  [timeSlotsState],
  time => time.timeSlotsSetting,
);

export const selectTimeSlotsExist = createSelector(
  [timeSlotsState],
  time => time.timeSlotsExists,
);

export const selectTimeSlots = createSelector(
  [timeSlotsState],
  time => time.timeSlots,
);
