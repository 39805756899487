import * as _ from 'lodash';
import { AppHelper } from 'utils/app.helper';

export class LocationService {
  static formatAddress(patient: any): string {
    let address: string = '';
    if (_.isEmpty(patient)) return address;
    const { street, ward_name, district_name, province_name } = patient;
    if (!AppHelper.checkEmptyString(street)) {
      address += `${street} `;
    }
    if (!AppHelper.checkEmptyString(ward_name)) {
      address += `${ward_name} `;
    }
    if (!AppHelper.checkEmptyString(district_name)) {
      address += `${district_name} `;
    }
    if (!AppHelper.checkEmptyString(province_name)) {
      address += `${province_name}`;
    }
    return address;
  }

  static findAndFormatAddress(provinces: any[], dataParse: any): string {
    let address: string = '';
    const addFormat = {
      street: '',
      province_name: '',
      district_name: '',
      ward_name: '',
    };
    if (!provinces.length || _.isEmpty(dataParse)) return address;
    const provinceId: string = _.get(dataParse, 'provinceCode');
    const distId: string = _.get(dataParse, 'districtCode');
    const wardId: string = _.get(dataParse, 'wardCode');
    const street: string = _.get(dataParse, 'street');
    if (!AppHelper.checkEmptyString(street)) {
      addFormat.street += street;
    }
    if (
      AppHelper.checkEmptyString(provinceId) ||
      AppHelper.checkEmptyString(distId) ||
      AppHelper.checkEmptyString(wardId)
    ) {
      return address;
    }
    const provinceSet = provinces.find(
      pro => pro['province_code'] === provinceId,
    );
    if (provinceSet) {
      addFormat.province_name = provinceSet['province_name'];
      const distSet = provinceSet['districts'].find(
        dist => dist['district_code'] === distId,
      );
      if (distSet) {
        addFormat.district_name = distSet['district_name'];
        const wardSet = distSet['wards'].find(
          ward => ward['ward_code'] === wardId,
        );
        if (wardSet) {
          addFormat.ward_name = wardSet['ward_name'];
        }
      }
    }
    return LocationService.formatAddress(addFormat);
  }
}
