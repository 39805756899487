export const ExamRoute = {
  REGISTER_EXAM: '/api/PatientOnline/registerd-examination',
  CANCEL_EXAM: '/api/PatientOnline/cancel-examination',
};

export const RESPONSE_CONSTANT = {
  REGISTER_EXAM_SUCCESS: 'REGISTER_EXAM_SUCCESS',
  REGISTER_EXAM_FAIL: 'REGISTER_EXAM_FAIL',
  CANCEL_EXAM_SUCCESS: 'CANCEL_EXAM_SUCCESS',
  CANCEL_EXAM_FAIL: 'CANCEL_EXAM_FAIL',
};

export const RESPONSE_MESSAGE = {
  CANCEL_EXAM_SUCCESS: 'Huỷ lịch khám thành công',
  CANCEL_EXAM_FAIL: 'Huỷ lịch khám thất bại',
  REGISTER_EXAM_SUCCESS: 'Đăng ký khám thành công',
  REGISTER_EXAM_FAIL: 'Đăng ký khám thành công',
};
