import { createSlice } from '@reduxjs/toolkit';
import {
  RESPONSE_CONSTANT,
  RESPONSE_MESSAGE,
} from 'store/roomOnline/constants';
import { AppHelper } from 'utils/app.helper';
export interface RoomOnlineState {
  loading: boolean;
  error: any;
  success?: any;
  rooms?: any;
  roomsSetting: any[];
  roomNotSetting: any[];
  roomById?: any;
}

export const initialState: RoomOnlineState = {
  loading: false,
  error: null,
  success: {},
  rooms: [],
  roomsSetting: [],
  roomNotSetting: [],
  roomById: {},
};

const roomOnlineSlice = createSlice({
  name: 'roomOnline',
  initialState,
  reducers: {
    getAllRooms(state, action) {
      state.loading = true;
    },

    getAllRoomsSuccess(state, action) {
      state.loading = false;
      state.rooms = new ReducerHelper().formatPayloadList(action.payload);
    },

    getAllRoomsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getAllRoomsSetting(state) {
      state.loading = true;
    },

    getAllRoomsSettingSuccess(state, action) {
      state.loading = false;
      state.roomsSetting = action.payload;
    },

    getAllRoomsSettingFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getAllRoomsNotSetting(state) {
      state.loading = true;
    },

    getAllRoomsNotSettingSuccess(state, action) {
      state.loading = false;
      state.roomNotSetting = action.payload;
    },

    getAllRoomsNotSettingFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    updateRoomSetting(state, action) {
      state.loading = true;
    },

    updateRoomSettingSuccess(state, action) {
      state.loading = false;
    },

    updateRoomSettingFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setRoomSetting(state, action) {
      state.loading = true;
      state.error = null;
    },

    setRoomSettingSuccess(state, action) {
      state.loading = false;
      state.success = {
        key: RESPONSE_CONSTANT.SET_CONFIG_TO_ROOM_SUCCESS,
        message: RESPONSE_MESSAGE.SET_CONFIG_TO_ROOM_SUCCESS,
        data: action.payload,
      };
    },

    setRoomSettingFail(state, action) {
      state.loading = false;
      state.error = {
        message: action.payload,
      };
    },

    setRoomEdit(state, action) {
      state.roomById = action.payload;
    },

    clearRoomEdit(state) {
      state.roomById = null;
    },

    clearSuccess(state) {
      state.success = null;
    },

    clearError(state) {
      state.error = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = roomOnlineSlice;

class ReducerHelper {
  public formatPayloadList(payload: any): any {
    return {
      ...payload,
      data: this.generateId(payload.data),
    };
  }

  public generateId(list: any[]): any[] {
    if (!list.length) return [];
    return list.map(item => {
      return item.id
        ? item
        : {
            ...item,
            id: AppHelper.generateUUID(),
          };
    });
  }
}
