import { createSlice } from '@reduxjs/toolkit';
import { AppHelper } from 'utils/app.helper';
import { RESPONSE_CONSTANT, RESPONSE_MESSAGE } from '../constants';

export interface PatientOnlineState {
  loading: boolean;
  error: any;
  success?: any;
  patients?: any;
  patient?: any;
}

export const initialState: PatientOnlineState = {
  loading: false,
  error: null,
  success: {},
  patients: [],
  patient: {},
};

const patientOnline = createSlice({
  name: 'patientOnline',
  initialState,
  reducers: {
    getPatients(state, action) {
      state.loading = true;
      state.error = null;
    },

    getPatientsSuccess(state, action) {
      state.loading = false;
      state.patients = action.payload;
    },

    getPatientsFail(state, action) {
      state.loading = false;
      state.error = {
        message: action.payload,
      };
    },

    getPatientByPhone(state, action) {
      state.loading = true;
      state.error = null;
    },

    getPatientByPhoneSuccess(state, action) {
      state.loading = false;
      state.patient = action.payload;
    },

    getPatientByPhoneFail(state, action) {
      state.loading = false;
      state.error = {
        message: action.payload,
      };
    },

    getPatientByCode(state, action) {
      state.loading = true;
      state.error = null;
    },

    getPatientByCodeSuccess(state, action) {
      state.loading = false;
      state.patient = action.payload;
    },

    getPatientByCodeFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    syncPatient(state, action) {
      state.loading = true;
      state.error = null;
    },

    syncPatientSuccess(state, action) {
      state.success = {
        id: AppHelper.generateUUID(),
        key: RESPONSE_CONSTANT.CANCEL_BOOKING_SUCCESS,
        message: RESPONSE_MESSAGE.CANCEL_BOOKING_SUCCESS,
        data: action.payload,
      };
      state.loading = false;
    },

    syncPatientFail(state, action) {
      state.loading = false;
      state.error = {
        id: AppHelper.generateUUID(),
        key: RESPONSE_CONSTANT.CANCEL_BOOKING_FAIL,
        message: action.payload
          ? action.payload
          : RESPONSE_MESSAGE.CANCEL_BOOKING_FAIL,
      };
    },

    clearSuccess(state) {
      state.success = null;
    },

    clearError(state) {
      state.error = null;
    },

    clearPatient(state) {
      state.patient = {};
    },

    clearPatients(state) {
      state.patients = {};
    },

    cancelBooking(state, action) {
      state.loading = true;
      state.error = null;
    },

    cancelBookingSuccess(state, action) {
      state.success = {
        id: AppHelper.generateUUID(),
        key: RESPONSE_CONSTANT.CANCEL_BOOKING_SUCCESS,
        message: RESPONSE_MESSAGE.CANCEL_BOOKING_SUCCESS,
        data: action.payload,
      };
      state.loading = false;
    },

    cancelBookingFail(state, action) {
      state.loading = false;
      state.error = {
        id: AppHelper.generateUUID(),
        key: RESPONSE_CONSTANT.CANCEL_BOOKING_FAIL,
        message: action.payload
          ? action.payload
          : RESPONSE_MESSAGE.CANCEL_BOOKING_FAIL,
      };
    },

    checkIn(state, action) {
      state.loading = true;
      state.error = null;
    },

    checkInSuccess(state, action) {
      state.success = {
        id: AppHelper.generateUUID(),
        key: RESPONSE_CONSTANT.CHECK_IN_SUCCESS,
        message: RESPONSE_MESSAGE.CHECK_IN_SUCCESS,
        data: action.payload,
      };
      state.loading = false;
    },

    checkInFail(state, action) {
      state.loading = false;
      state.error = {
        id: AppHelper.generateUUID(),
        key: RESPONSE_CONSTANT.CHECK_IN_FAIL,
        message: action.payload
          ? action.payload
          : RESPONSE_MESSAGE.CHECK_IN_FAIL,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = patientOnline;
